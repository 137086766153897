import React from 'react';
import { useWixSdk, useEnvironment } from '@wix/yoshi-flow-editor';
import GogWrapper from './GogWrapper';
import './wixStyles.global.scss';

interface WidgetWrapperProps {
  visitorId: string;
  userOwnership: string;
  appName: string;
  mobile: boolean;
  language: string;
  translations: Record<string, string>;
  host: any;
  instance: string;
  instanceId: string;
  biToken: string;
  updateLayout: any;
  viewMode: string;
  setHeight: any;
  albumsCovers: GalleryItem[];
  coverIdToAlbum: { [id: string]: GogAlbum };
  gogData: GogData;
  fetchAlbums: () => Promise<{
    gogData: any;
    albumsCovers: GalleryItem[];
    coverIdToAlbum: {
      [id: string]: GogAlbum;
    };
  }>;
  getDefaultAlbumCovers: (isNewDefaultAlbums: boolean) => GalleryItem[];
  getTemplateDefaultAlbums: () => Promise<GogAlbum[]>;
  isSSR?: boolean;
  experiments?: any;
}

export default ({
  visitorId,
  userOwnership,
  mobile,
  host,
  instance,
  instanceId,
  biToken,
  updateLayout,
  setHeight,
  albumsCovers,
  coverIdToAlbum,
  gogData,
  fetchAlbums,
  getDefaultAlbumCovers,
  getTemplateDefaultAlbums,
}: WidgetWrapperProps) => {
  // Fix For Editor Preview Mode
  // Need it only when the GoG is set to stretch
  if (host.dimensions.width === '100%') {
    host.dimensions.width = '';
  }

  const { isSSR, isMobile } = useEnvironment();
  const { Wix } = useWixSdk();

  return (
    <GogWrapper
      visitorId={visitorId}
      userOwnership={userOwnership}
      Wix={Wix}
      host={host}
      instance={instance}
      instanceId={instanceId}
      biToken={biToken}
      mobile={isMobile}
      updateLayout={updateLayout}
      viewMode={host.viewMode}
      setHeight={setHeight}
      albumsCovers={albumsCovers}
      gogData={gogData}
      coverIdToAlbum={coverIdToAlbum}
      fetchAlbums={fetchAlbums}
      getDefaultAlbumCovers={getDefaultAlbumCovers}
      getTemplateDefaultAlbums={getTemplateDefaultAlbums}
      isSSR={isSSR}
    />
  );
};

export const Widget = GogWrapper;
